











































































































































































































































































































































































































import GiftRedemptionModule from '@/store/modules/gift-redemption'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import validator from 'validator'
import { ShippingEligiblity } from '@/modules/common'
import { PostalCodeLookup } from '@/apis/signup'
import PackCard from '@/components/common/packs/card/index.vue'
import m from 'moment'
import CommonModule from '@/store/modules/common'

@Component({
  components: { PackCard }
})
export default class GiftRedemptionConfirm extends Vue {
  giftRedemptionModule: GiftRedemptionModule = getModule(
    GiftRedemptionModule,
    this.$store
  );

  commonModule: CommonModule = getModule(CommonModule, this.$store);

  private localFirstName = '';

  private localLastName = '';

  private localMobilePhone = '';

  private localEmailAddress: string | undefined = '';

  shouldShowAlert = false;

  zipCodeError = '';

  streetAddressLine1 = '';

  streetAddressLine2 = '';

  specialInstructions = '';

  localCity = '';

  localState = '';

  localPostalCode = '';

  originalPostalCode = '';

  mask = '#####';

  lazy = true;

  valid = false;

  shippingWarning = '';

  errorMessage = '';

  created() {
    this.updateDataFromStore()
  }

  async mounted() {
    await this.commonModule.SetQuickActionTitle('Enter Shipping Information')
    if (this.firstName === '') {
      (this.$refs.firstName as any).focus()
    } else if (this.lastName === '') {
      (this.$refs.lastName as any).focus()
    } else if (this.mobilePhone === '') {
      (this.$refs.mobilePhone as any).focus()
    }
    if (this.giftRedemptionModule.orderId !== '' && this.giftRedemptionModule.orderId !== null) {
      this.$router.push({
        name: 'gift-redemption.complete'
      })
    }
  }

  get computeSizes() {
    if (this.$vuetify.breakpoint.xs) {
      return '20.4vw'
    }
    if (this.$vuetify.breakpoint.sm) {
      return '12vw'
    }
    if (this.$vuetify.breakpoint.xl) {
      return '6vw'
    }
    return '8vw'
  }

  async sendGift() {
    if (this.valid) {
      this.errorMessage = ''
      await this.updatePersonalInfo()
      await this.updateShippingInfo()
      this.giftRedemptionModule.SetShippingWeek(this.shippingWeek)
      this.$emit('toggle-overlay', { message: 'Please wait, we are placing your gift order.' })
      await this.giftRedemptionModule.ProcessGiftCardSignup()
      this.$emit('toggle-overlay', { message: '' })
      if (this.giftRedemptionModule.orderId !== '' && this.giftRedemptionModule.orderId !== null) {
        this.$router.push({
          name: 'gift-redemption.complete'
        })
      } else {
        this.errorMessage = this.giftRedemptionModule.errorMessage
      }
    }
  }

  get firstName() {
    return this.localFirstName
  }

  set firstName(value: string) {
    this.localFirstName = value
  }

  get lastName() {
    return this.localLastName
  }

  set lastName(value: string) {
    this.localLastName = value
  }

  get mobilePhone() {
    if (this.localMobilePhone.length === 10) {
      return `(${this.localMobilePhone.substr(
        0,
        3
      )}) ${this.localMobilePhone.substr(3, 3)}-${this.localMobilePhone.substr(
        6
      )}`
    }
    return this.localMobilePhone
  }

  set mobilePhone(value) {
    let cleansed = value
    if (cleansed) {
      cleansed = value.replace(/[^0-9]/g, '')
      if (cleansed.startsWith('1')) {
        cleansed = cleansed.substring(1)
      }

      if (cleansed.length === 10) {
        cleansed = `(${cleansed.substr(0, 3)}) ${cleansed.substr(
          3,
          3
        )}-${cleansed.substr(6)}`
      }
    }
    this.localMobilePhone = cleansed
  }

  get emailAddress(): string | undefined {
    return this.localEmailAddress
  }

  set emailAddress(value: string | undefined) {
    this.localEmailAddress = value
  }

  get city() {
    return this.giftRedemptionModule.signupInfo.shippingAddress.address.city
  }

  set city(value) {
    this.localCity = value
  }

  get state() {
    return this.giftRedemptionModule.signupInfo.shippingAddress.address.state
  }

  set state(value) {
    this.localState = value
  }

  get postalCode() {
    return this.localPostalCode
  }

  set postalCode(value) {
    this.shouldShowAlert = false
    this.localPostalCode = value
  }

  private get customerSuppliedPostalCode(): string {
    if (
      this.giftRedemptionModule.signupInfo.shippingAddress.address.postalCode
    ) {
      return this.giftRedemptionModule.signupInfo.shippingAddress.address
        .postalCode
    }
    return ''
  }

  private updateDataFromStore() {
    if (
      this.giftRedemptionModule.signupInfo.customerContactInfo.firstName &&
      this.giftRedemptionModule.signupInfo.customerContactInfo.lastName
    ) {
      this.localFirstName = this.giftRedemptionModule.signupInfo.customerContactInfo.firstName
      this.localLastName = this.giftRedemptionModule.signupInfo.customerContactInfo.lastName
    }
    if (
      this.giftRedemptionModule.signupInfo.customerContactInfo.phoneNumber &&
      validator.isMobilePhone(
        this.giftRedemptionModule.signupInfo.customerContactInfo.phoneNumber,
        'en-US'
      )
    ) {
      this.mobilePhone = this.giftRedemptionModule.signupInfo.customerContactInfo.phoneNumber
    }

    if (
      this.giftRedemptionModule.signupInfo.customerContactInfo.emailAddress &&
      validator.isEmail(
        this.giftRedemptionModule.signupInfo.customerContactInfo.emailAddress
      )
    ) {
      this.emailAddress = this.giftRedemptionModule.signupInfo.customerContactInfo.emailAddress
    }
    if (
      this.giftRedemptionModule.signupInfo.shippingAddress
        .specialInstructions &&
      !validator.isEmpty(
        this.giftRedemptionModule.signupInfo.shippingAddress.specialInstructions
      )
    ) {
      this.specialInstructions = this.giftRedemptionModule
        .signupInfo.shippingAddress.specialInstructions
    }
    if (
      this.giftRedemptionModule.signupInfo.shippingAddress.address
        .streetAddressLine1 &&
      !validator.isEmpty(
        this.giftRedemptionModule.signupInfo.shippingAddress.address
          .streetAddressLine1
      )
    ) {
      this.streetAddressLine1 = this.giftRedemptionModule.signupInfo
        .shippingAddress.address.streetAddressLine1
    }
    if (
      this.giftRedemptionModule.signupInfo.shippingAddress.address
        .streetAddressLine2 &&
      !validator.isEmpty(
        this.giftRedemptionModule.signupInfo.shippingAddress.address
          .streetAddressLine2
      )
    ) {
      this.streetAddressLine2 = this.giftRedemptionModule.signupInfo
        .shippingAddress.address.streetAddressLine2
    }
  }

  private async updatePersonalInfo() {
    if (this.valid) {
      await this.giftRedemptionModule.AddCustomerName({
        firstName: this.localFirstName,
        lastName: this.localLastName
      })
      await this.giftRedemptionModule.AddCustomerMobilePhone(this.mobilePhone)
      // await this.giftRedemptionModule.AddCustomerPassword(this.localPassword);
      if (this.localEmailAddress && validator.isEmail(this.localEmailAddress)) {
        await this.giftRedemptionModule.UpdateUsername(this.localEmailAddress)
      } else if (this.emailAddress) {
        await this.giftRedemptionModule.UpdateUsername(this.emailAddress)
      }
    }
  }

  get deliveryWeeks() {
    return this.giftRedemptionModule.EligibleGiftShippingWeeks.map((p) => m(p).format('L'))
  }

  get shippingWeek() {
    if (this.giftRedemptionModule.signupInfo.firstShipmentDate) {
      return this.giftRedemptionModule.signupInfo.firstShipmentDate
    }
    return this.giftRedemptionModule.EligibleGiftShippingWeeks[0]
  }

  get formattedShippingWeek() {
    if (this.giftRedemptionModule.signupInfo.firstShipmentDate) {
      return m(this.giftRedemptionModule.signupInfo.firstShipmentDate).format(
        'L'
      )
    }
    return m(this.giftRedemptionModule.EligibleGiftShippingWeeks[0]).format(
      'L'
    )
  }

  set formattedShippingWeek(value: string) {
    this.giftRedemptionModule.SetShippingWeek((m(value) as unknown) as Date)
  }

  get firstDeliveryDay(): string {
    return m(this.shippingWeek).add(2, 'days').format('ddd L')
  }

  get lastDeliveryDay(): string {
    return m(this.shippingWeek).add(4, 'days').format('ddd L')
  }

  get hasYetToBePlaced() {
    return (
      this.giftRedemptionModule.orderId === '' ||
      this.giftRedemptionModule.orderId === undefined
    )
  }

  get hasPlaced() {
    return (
      this.giftRedemptionModule.orderId !== '' &&
      this.giftRedemptionModule.orderId !== undefined
    )
  }

  private async updateShippingInfo() {
    if (this.valid) {
      this.giftRedemptionModule.SetShippingAddress({
        streetAddressLine1: this.streetAddressLine1,
        streetAddressLine2: this.streetAddressLine2,
        specialInstructions: this.specialInstructions
      })
    }
  }

  @Watch('localPostalCode')
  async onLocalPostCodeChange(newValue: string) {
    if (
      newValue &&
      newValue.length === 5 &&
      newValue !== this.originalPostalCode
    ) {
      const result = await PostalCodeLookup({
        postalCode: newValue,
        isGift: true
      })
      if (result.shippingEligiblity === ShippingEligiblity.Ineligible) {
        this.localPostalCode = this.originalPostalCode
        this.zipCodeError = `Sorry, we don't ship to ${result.city}, ${result.state}`
        this.shouldShowAlert = true
      } else if (result.shippingEligiblity === ShippingEligiblity.Unknown) {
        this.localPostalCode = this.originalPostalCode
        this.zipCodeError = `Sorry, ${result.postalCode} is not recoginized zip code.`
        this.shouldShowAlert = true
      } else {
        this.originalPostalCode = result.postalCode
        this.zipCodeError = ''
        this.shouldShowAlert = false
        await this.giftRedemptionModule.ConfirmShippingAddress(newValue)
      }
    }
  }

  get giftPack() {
    return this.giftRedemptionModule.giftPack
  }

  get packName() {
    return `${this.giftRedemptionModule.signupInfo.customerContactInfo.firstName}'s Gift Pack`
  }

  firstNameRules = [(v: string) => !!v || 'First name is required'];

  lastNameRules = [(v: string) => !!v || 'Last name is required'];

  emailRules = [
    (v: string) => !!v || 'E-mail is required',
    (v: string) => /.+@.+\..+/.test(v) || 'E-mail appears to be invalid.'
  ];

  mobilePhoneRules = [
    (v: string) => !!v || 'Mobile phone is required',
    (v: string) => /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/.test(v) ||
      'Mobile phone appears to be invalid.'
  ];

  streetAddressLine1Rules = [
    (v: string) => !!v || 'Street Address is required'
  ];

  postalCodeRules = [
    (v: string) => !!v || 'Zip code is required',
    (v: string) => /^\d{5}([-]|\s*)?(\d{4})?$/.test(v) || 'Zip code must have five numbers.'
  ];
}
