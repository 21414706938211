



















































































































import CommonModule from '@/store/modules/common'
import GiftRedemptionModule from '@/store/modules/gift-redemption'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import m from 'moment'
import PackCard from '@/components/common/packs/card/index.vue'

@Component({
  components: { PackCard }
})
export default class GiftRedemptiomComplete extends Vue {
  giftRedemptionModule: GiftRedemptionModule = getModule(
    GiftRedemptionModule,
    this.$store
  );

    commonModule: CommonModule = getModule(
      CommonModule,
      this.$store
    );

    async mounted() {
      await this.commonModule.SetQuickActionTitle('You are all set!')
    }

    get packName() {
      return `${this.giftRedemptionModule.signupInfo.customerContactInfo.firstName}'s Gift Pack`
    }

    get orderId() {
      return this.giftRedemptionModule.orderId
    }

    get giftPack() {
      return this.giftRedemptionModule.giftPack
    }

    get shippingAddress() {
      return this.giftRedemptionModule.signupInfo.shippingAddress
    }

    get emailAddress() {
      return this.giftRedemptionModule.signupInfo.customerContactInfo.emailAddress
    }

    get phoneNumber() {
      return this.giftRedemptionModule.signupInfo.customerContactInfo.phoneNumber
    }

    get subscriberName() {
      return `${this.giftRedemptionModule.signupInfo.customerContactInfo.firstName}
     ${this.giftRedemptionModule.signupInfo.customerContactInfo.lastName}`
    }

    get shippingWeek() {
      return this.giftRedemptionModule.signupInfo.firstShipmentDate
    }

    get firstDeliveryDay(): string {
      return m(this.shippingWeek).add(2, 'days').format('ddd L')
    }

    get lastDeliveryDay(): string {
      return m(this.shippingWeek).add(4, 'days').format('ddd L')
    }
}
