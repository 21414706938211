
























import GiftRedemptionModule from '@/store/modules/gift-redemption'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'

@Component({
  components: {}
})
export default class GiftRedemption extends Vue {
  giftRedemptionModule: GiftRedemptionModule = getModule(
    GiftRedemptionModule,
    this.$store
  );

  message = '';

  overlay = false;

  async verifyGiftCard(giftCard: string) {
    this.toggleOverlay({ message: 'Please wait, we are verifying your gift card.' })
    await this.giftRedemptionModule.ConfirmGiftCard(giftCard)
    this.toggleOverlay({ message: '' })
  }

  async toggleOverlay(input: { message: string}) {
    this.message = input.message
    this.overlay = !this.overlay
  }
}
