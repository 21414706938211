















import GiftRedemptionModule from '@/store/modules/gift-redemption'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import ItemSelectorList from '@/components/common/ItemSelectorList/index.vue'
import { PackSize } from '@/modules/common'
import CommonModule from '@/store/modules/common'

@Component({
  components: { ItemSelectorList }
})
export default class GiftRedemptionCustomize extends Vue {
  giftRedemptionModule: GiftRedemptionModule = getModule(GiftRedemptionModule, this.$store)

  commonModule: CommonModule = getModule(CommonModule, this.$store)

  async mounted() {
    await this.commonModule.SetQuickActionTitle('Customize Your Pack')
    if (this.giftRedemptionModule.giftPackItems.length === 0) {
      await this.giftRedemptionModule.CustomizePreconfiguredCustomPack({
        id: this.giftRedemptionModule.giftPack.id,
        size: PackSize.Standard
      })
    }
  }

  private subsituteItem(input: { itemIndex: number; newItemId: string }) {
    this.giftRedemptionModule.SwapItem(input)
  }

  private async processCustomizations() {
    await this.giftRedemptionModule.EnsureCustomizedItems()
    this.$router.push({
      name: 'gift-redemption.confirm'
    })
  }

  get packItems() {
    return this.giftRedemptionModule.giftPackItems
  }

  get packProteins() {
    // return this.giftRedemptionModule.giftPack.proteins
    return []
  }

  get packName() {
    return this.giftRedemptionModule.packName
  }
}
