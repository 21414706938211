





























































import GiftRedemptionModule from '@/store/modules/gift-redemption'
import CommonModule from '@/store/modules/common'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import PackCard from '@/components/common/packs/card/index.vue'
import { getModule } from 'vuex-module-decorators'
import { Frequency, PackSize } from '@/modules/common'

@Component({
  components: { PackCard }
})
export default class GiftRedemptionPickGiftPack extends Vue {
  loaded = false

  giftRedemptionModule: GiftRedemptionModule = getModule(GiftRedemptionModule, this.$store)

  commonModule: CommonModule = getModule(CommonModule, this.$store)

  async mounted() {
    this.$emit('toggle-overlay', { message: 'Please wait, we are loading your gift options.' })
    await this.commonModule.SetQuickActionTitle('Choose Your Pack')
    await this.giftRedemptionModule.Startup()
    this.loaded = true
    this.$emit('toggle-overlay', { message: '' })
  }

  get featuredPacks() {
    return this.giftRedemptionModule.featuredPacks
  }

  // eslint-disable-next-line class-methods-use-this
  get frequency() {
    return Frequency.Unknown
  }

  // eslint-disable-next-line class-methods-use-this
  get packSize() {
    return PackSize.Standard
  }

  pickProteins() {
    this.$router.push({
      name: 'gift-redemption.pick-proteins'
    })
  }

  async selected(input: { id: string; personalize: boolean; size: PackSize }) {
    if (!input.personalize) {
      await this.giftRedemptionModule.UpdateNextPackWithPreconfiguredItems(input)
      this.$router.push({
        name: 'gift-redemption.confirm'
      })
    } else {
      await this.giftRedemptionModule.UpdateNextPackWithPreconfiguredItems(input)
      await this.giftRedemptionModule.CustomizePreconfiguredCustomPack(input)
      this.$router.push({
        name: 'gift-redemption.customize'
      })
    }
  }
}
