import * as giftCardApi from '@/apis/gift-card'
import store from '@/store'
import {
  SubscriberStatus,
  ShippingEligiblity,
  ShippingCenter,
  LegacyPackItem,
  ProteinType,
  ShellfishCheck,
  Frequency,
  PackSize,
  ShippingEligibiltyResult,
  Item,
  SignupResult,
  ItemTemplateConfiguration,
  CommonItemConfiguration,
  SubstituteItem
} from '@/modules/common'
import {
  GetQuickAccessBYOCustomPackItems,
  GetQuickAccessBYOFeautedPacks,
  GetQuickAccessBYOSubsituteItems
} from '@/apis/quick-access'
import { GetItems, ConfirmShippingInformationNoEmail } from '@/apis/signup'
import { GiftCard, GiftCardSignup, GiftCardStatus } from '@/modules/gift-redemption'
import { SignupStatus } from '@/modules/signup'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import validator from 'validator'
import { orderBy, sortBy, uniq } from 'lodash'
import { ProcessGiftCardSignup } from '@/apis/gift-card'
import { GiftRedemptionState } from './types'
import { ItemTemplateType, ProductConfigurationTemplateItem, ProductConfigurationTemplateType } from '@/modules/byo'

@Module({ dynamic: true, store, name: 'gift-redemption' })
export default class GiftRedemptionModule extends VuexModule implements GiftRedemptionState {
  orderId = ''

  subscriberStatus = SubscriberStatus.Unknown

  shippingEligiblity = ShippingEligiblity.Unknown

  shippingCenter = ShippingCenter.Unknown

  numberOfShippingDays = 0

  signupInfo: GiftCardSignup = {
    customerContactInfo: {
      emailAddress: '',
      firstName: undefined,
      lastName: undefined,
      phoneNumber: undefined
    },
    giftCard: {
      giftCardId: '',
      issueDate: '',
      expirationDate: '',
      redemptionDate: '',
      fromFriendly: '',
      toFriendly: '',
      note: '',
      recipientEmailAddress: '',
      recipientFirstName: '',
      recipientLastName: '',
      giftCardStatus: GiftCardStatus.Unknown
    },
    firstShipmentDate: undefined,
    shippingAddress: {
      address: {
        streetAddressLine1: '',
        streetAddressLine2: undefined,
        city: '',
        state: '',
        postalCode: ''
      },
      specialInstructions: undefined
    },
    subscriptionConfiguration: {
      proteinTypes: [],
      frequency: Frequency.Unknown,
      packSize: PackSize.Standard
    }
  }

  signupStatus = SignupStatus.Unknown

  errorMessage = ''

  selectedItems: Array<ProductConfigurationTemplateItem> = []

  selectedProteins: Array<ProteinType> = []

  shellfishAlergyStatus = ShellfishCheck.Unknown

  selectedCommonItemConfiguration = undefined

  commonItemConfigurations = []

  shippingWeeks: Array<Date> = []

  featuredPacks: Array<ItemTemplateConfiguration> = []

  items: Array<Item> = []

  packName = ''

  giftPack: ItemTemplateConfiguration = {
    id: '',
    description: '',
    name: '',
    shippingCenter: ShippingCenter.EastCoastCenter2,
    isReadonly: false,
    activeTemplate: {
      productConfigurationTemplateId: '',
      productConfigurationTemplateType: ProductConfigurationTemplateType.Unknown,
      contactId: '',
      templateName: '',
      packSize: PackSize.Unknown,
      maximumProductPrice: 0,
      surcharge: 0,
      isActiveTemplate: false,
      items: [],
      createdDate: new Date('1/1/1900'),
      updatedDate: new Date('1/1/1900')
    },
    surcharge: 0,
    discount: 0,
    listPrice: 0
  }

  giftPackItems: Array<ProductConfigurationTemplateItem> = []

  get EligibleGiftShippingWeeks() {
    return this.shippingWeeks
  }

  get giftCardStatus() {
    switch (this.signupInfo.giftCard.giftCardStatus) {
      case GiftCardStatus.NotFound:
        return 'Sorry, the gift card you provided is not valid.'
      case GiftCardStatus.Expired:
        return `Sorry, the gift card you provided expired on ${this.signupInfo.giftCard.expirationDate}.`
      case GiftCardStatus.Reedeemed:
        return `Sorry, the gift card you provided was already redeemed on ${this.signupInfo.giftCard.redemptionDate}.`
      case GiftCardStatus.Revoked:
        return 'Sorry, the gift card you provided was revoked, please contact the gifter.'
      case GiftCardStatus.Issued:
        return `The gift card you provided is valid and expires on ${this.signupInfo.giftCard.expirationDate}`
      case GiftCardStatus.Replaced:
        return 'Sorry, the gift card you provided was replaced, please contact the gifter.'
      case GiftCardStatus.Unknown:
        return ''
      default:
        return ''
    }
  }

  get hasValidGiftCard() {
    return this.signupInfo.giftCard.giftCardStatus === GiftCardStatus.Issued
  }

  get giftCardInfo() {
    if (this.signupInfo.giftCard.giftCardStatus === GiftCardStatus.Issued) {
      return {
        To: this.signupInfo.giftCard.toFriendly,
        From: this.signupInfo.giftCard.fromFriendly,
        Note: this.signupInfo.giftCard.note
      }
    }
    return { To: '', From: '', Note: '' }
  }

  @Mutation
  SET_GiftCard(giftCard: GiftCard) {
    Object.assign(this.signupInfo.giftCard, giftCard)
    if (giftCard.recipientEmailAddress !== null && validator.isEmail(giftCard.recipientEmailAddress)) {
      this.signupInfo.giftCard.recipientEmailAddress = ''
      this.signupInfo.customerContactInfo.emailAddress = giftCard.recipientEmailAddress
    }
    if (giftCard.recipientFirstName && !validator.isEmpty(giftCard.recipientFirstName)) {
      this.signupInfo.giftCard.recipientFirstName = ''
      this.signupInfo.customerContactInfo.firstName = giftCard.recipientFirstName
    }
    if (giftCard.recipientLastName && !validator.isEmpty(giftCard.recipientLastName)) {
      this.signupInfo.giftCard.recipientLastName = ''
      this.signupInfo.customerContactInfo.lastName = giftCard.recipientLastName
    }
  }

  @Action
  async ConfirmGiftCard(giftCardId: string) {
    const giftCard = await giftCardApi.confirmGiftCard(giftCardId)
    this.context.commit('SET_GiftCard', giftCard)
  }

  @Mutation
  private SET_ShippingConfiguration(result: ShippingEligibiltyResult) {
    this.shippingCenter = result.shippingCenter
    this.shippingEligiblity = result.shippingEligiblity
    this.numberOfShippingDays = result.numberOfShippingDays
    this.shippingWeeks = result.shippingWeeks.map(p => new Date(p))
    this.signupInfo.shippingAddress.address.city = result.city
    this.signupInfo.shippingAddress.address.state = result.state
    this.signupInfo.shippingAddress.address.postalCode = result.postalCode
  }

  @Action
  async ConfirmShippingAddress(postalCode: string) {
    const result = await ConfirmShippingInformationNoEmail({ postalCode, isGift: true })
    this.context.commit('SET_ShippingConfiguration', result)
  }

  @Mutation
  SET_FeaturedPacks(featuredPacks: CommonItemConfiguration[]) {
    this.featuredPacks.length = 0
    const configs = featuredPacks.map(p => ({
      id: p.id,
      shippingCenter: ShippingCenter.EastCoastCenter2,
      name: p.name,
      description: p.description,
      activeTemplate: {
        contactId: '',
        productConfigurationTemplateId: '',
        productConfigurationTemplateType: ProductConfigurationTemplateType.Standard,
        templateName: `Template From ${p.id}`,
        packSize: PackSize.Standard,
        maximumProductPrice: 97,
        surcharge: 0,
        isActiveTemplate: true,
        items: p.items.map(p => ({
          productConfigurationTemplateId: '',
          itemId: parseInt(p.id),
          item: p.item,
          itemTemplateTypeId: ItemTemplateType.StandardReoccuring,
          referenceItemPrice: p.maxSwapPrice ?? 0,
          surcharge: 0,
          quantity: 0,
          shippingQuantity: p.shippingQuantity,
          displayOrder: p.displayOrder / 100,
          createdDate: new Date(Date.now()),
          updatedDate: new Date(Date.now()),
          id: p.id,
          subsituteItems: new Array<SubstituteItem>()
        })),
        createdDate: new Date(Date.now()),
        updatedDate: new Date(Date.now())
      },
      isReadonly: false,
      surcharge: 0,
      discount: 0,
      listPrice: 0
    }))

    // configs.forEach(p => {
    //   p.activeTemplate.items.push(
    //     ...this.activeConfiguration.activeTemplate.items.filter(
    //       p =>
    //         p.itemTemplateTypeId === ItemTemplateType.FreeOnetime ||
    //         p.itemTemplateTypeId === ItemTemplateType.FreeReoccuring
    //     )
    //   )

    //   let i = 0
    //   sortBy(p.activeTemplate.items, p => p.displayOrder).forEach(q => {
    //     if (
    //       q.itemTemplateTypeId !== ItemTemplateType.FreeOnetime &&
    //       q.itemTemplateTypeId !== ItemTemplateType.FreeReoccuring
    //     ) {
    //       q.displayOrder = i++
    //     } else {
    //       q.id = q.itemId.toString()
    //       q.displayOrder = 99 + i
    //     }
    //   })
    //   p.activeTemplate.items = sortBy(p.activeTemplate.items, p => p.displayOrder)
    // })
    this.featuredPacks.push(...configs)
  }

  @Mutation
  private SET_Items(items: Array<Item>) {
    this.items.length = 0
    this.items.push(...items)
  }

  @Mutation
  private SET_AttachPackItems() {
    this.featuredPacks.forEach(p => {
      p.activeTemplate.items.forEach(o => {
        // eslint-disable-next-line no-param-reassign
        o.item = this.items.find(q => q.id === o.id)
        if (o.subsituteItems) {
          o.subsituteItems.forEach(r => {
            // eslint-disable-next-line no-param-reassign
            r.item = this.items.find(r1 => r1.id === r.id)
          }, this)
        }
      }, this)
    })
  }

  @Action
  async Startup() {
    const items = GetItems()
    const featuredPacks = GetQuickAccessBYOFeautedPacks()
    const results = await Promise.all([items, featuredPacks])
    this.context.commit('SET_Items', results[0])
    this.context.commit('SET_FeaturedPacks', results[1])
    this.context.commit('SET_AttachPackItems')
  }

  @Mutation
  private SET_PackName(name: string) {
    this.packName = name
  }

  @Mutation
  private SET_PackItems(packItems: Array<ProductConfigurationTemplateItem>) {
    this.selectedItems.length = 0
    if (packItems) {
      packItems.forEach(p => {
        const pi = p
        if (!pi.item) {
          pi.item = this.items.find(q => q.id === p.id)
        }
      })
      this.selectedItems.push(...packItems)
    }
  }

  @Mutation
  private SET_Proteins(proteins: Array<ProteinType>) {
    this.selectedProteins.length = 0
    this.selectedProteins.push(...proteins)
  }

  @Mutation
  SET_GiftPack(giftPack: ItemTemplateConfiguration) {
    this.giftPack.name = giftPack.name
    this.giftPack.isReadonly = giftPack.isReadonly
    // this.giftPack.proteins.length = 0
    // this.giftPack.proteins.push(...giftPack.proteins)
    this.giftPack.id = giftPack.id
    this.giftPack.activeTemplate.items.length = 0
    this.giftPack.activeTemplate.items.push(...giftPack.activeTemplate.items)
    this.giftPack.shippingCenter = giftPack.shippingCenter
  }

  @Action
  async UpdateNextPackWithPreconfiguredItems(input: { id: string }) {
    const packItems: Array<ProductConfigurationTemplateItem> = []
    const proteins: Array<ProteinType> = []
    packItems.push(...this.featuredPacks.find(p => p.id === input.id)!.activeTemplate.items)
    proteins.push(
      ...uniq(this.featuredPacks.find(p => p.id === input.id)!.activeTemplate.items.map(p => p.item!.proteinType))
    )

    this.context.commit(
      'SET_GiftPack',
      this.featuredPacks.find(p => p.id === input.id)
    )
    this.context.commit('SET_PackName', this.featuredPacks.find(p => p.id === input.id)?.name)
    this.context.commit('SET_PackItems', packItems)
    this.context.commit('SET_Proteins', proteins)
    this.context.commit('SET_GiftPackItems', packItems)
  }

  @Mutation
  ENSURE_GiftPackItems() {
    this.selectedItems.length = 0
    this.selectedItems.push(...this.giftPackItems)
    this.giftPack.activeTemplate.items.length = 0
    this.giftPack.activeTemplate.items.push(...this.giftPackItems)
    this.selectedProteins.length = 0
    // this.giftPack.proteins.length = 0
    this.selectedProteins.push(...sortBy(uniq(this.selectedItems.map(p => p.item!.proteinType))))
    // this.giftPack.proteins.push(...sortBy(uniq(this.selectedItems.map(p => p.item!.proteinType))))
  }

  @Action
  async EnsureCustomizedItems() {
    this.context.commit('ENSURE_GiftPackItems')
  }

  @Mutation
  private SET_CustomerContact(input: {
    emailAddress: string
    lastName: string
    firstName: string
    phoneNumber: string
  }) {
    if (input.emailAddress && validator.isEmail(input.emailAddress.trim())) {
      this.signupInfo.customerContactInfo.emailAddress = input.emailAddress.trim()
    }
    if (input.lastName && !validator.isEmpty(input.lastName)) {
      this.signupInfo.customerContactInfo.lastName = input.lastName
    }
    if (input.firstName && !validator.isEmpty(input.firstName)) {
      this.signupInfo.customerContactInfo.firstName = input.firstName
    }
    if (input.phoneNumber && validator.isMobilePhone(input.phoneNumber, 'en-US')) {
      this.signupInfo.customerContactInfo.phoneNumber = input.phoneNumber
    }
  }

  @Action async AddCustomerMobilePhone(phone: string) {
    this.context.commit('SET_CustomerContact', {
      phoneNumber: phone
    })
  }

  @Action async AddCustomerName(input: { firstName: string; lastName: string }) {
    this.context.commit('SET_CustomerContact', {
      firstName: input.firstName,
      lastName: input.lastName
    })
  }

  @Action
  async UpdateUsername(value: string) {
    let username = ''
    if (value) {
      username = value.trim()
    }
    if (validator.isEmail(username) && username !== this.signupInfo.customerContactInfo.emailAddress) {
      this.context.commit('SET_CustomerContact', { emailAddress: username })
    }
  }

  @Action async SetShippingAddress(input: {
    streetAddressLine1: string
    streetAddressLine2: string
    specialInstructions: string
  }) {
    this.context.commit('SET_ShippingAddress', input)
  }

  @Mutation
  private SET_ShippingAddress(input: {
    postalCode: string
    state: string
    city: string
    streetAddressLine1: string
    streetAddressLine2: string
    specialInstructions: string
  }) {
    if (input.postalCode && input.postalCode.length === 5) {
      this.signupInfo.shippingAddress.address.postalCode = input.postalCode
    }
    if (input.state && input.state.length === 2) {
      this.signupInfo.shippingAddress.address.state = input.state
    }
    if (input.city && input.city.length > 0) {
      this.signupInfo.shippingAddress.address.city = input.city
    }
    if (input.streetAddressLine1 !== undefined) {
      this.signupInfo.shippingAddress.address.streetAddressLine1 = input.streetAddressLine1
    }
    if (input.streetAddressLine2 !== undefined) {
      this.signupInfo.shippingAddress.address.streetAddressLine2 = input.streetAddressLine2
    }
    if (input.specialInstructions !== undefined) {
      this.signupInfo.shippingAddress.specialInstructions = input.specialInstructions
    }
  }

  @Mutation SET_ShippingWeek(input: Date) {
    this.signupInfo.firstShipmentDate = input
  }

  @Action
  async SetShippingWeek(value: Date) {
    this.context.commit('SET_ShippingWeek', value)
  }

  @Action
  SwapItem(input: { itemIndex: number; newItemId: string }) {
    this.context.commit('PROCESS_SwapItem', input)
  }

  @Mutation
  private PROCESS_SwapItem(input: { itemIndex: number; newItemId: string }) {
    const itemToSwapOut = this.giftPackItems[input.itemIndex]
    const itemToSwapIn = itemToSwapOut.subsituteItems.find(p => p.id === input.newItemId)?.item

    if (itemToSwapIn && itemToSwapOut.item) {
      itemToSwapOut.id = itemToSwapIn?.id
      itemToSwapOut.item = itemToSwapIn
    }
  }

  @Mutation
  private SET_AttachSubsituteItemsToPreconfiguredPack(input: {
    id: string
    subsitutes: Array<{
      id: number
      subsituteItems: Array<{ subsituteItemId: number; displayOrder: number }>
    }>
  }) {
    let items: Array<ProductConfigurationTemplateItem> = []
    if (input.id === this.giftPack.id) {
      items = this.giftPack.activeTemplate.items
    } else if (input.id !== 'NONE') {
      items = this.featuredPacks.find(p => p.id === input.id)!.activeTemplate.items
    } else {
      items = this.giftPackItems
    }

    input.subsitutes.forEach(p => {
      const matchingItems = items.filter(q => q.displayOrder === p.id || Number.parseInt(q.id, 10) === p.id)
      matchingItems.forEach(x => {
        const item = x
        if (item) {
          if (item.subsituteItems === undefined) {
            item.subsituteItems = []
          } else {
            item.subsituteItems.length = 0
          }
          const mappedItems = p.subsituteItems.map(r => ({
            item: this.items.find(s => s.id === r.subsituteItemId.toString()),
            id: r.subsituteItemId.toString(),
            displayOrder: r.displayOrder
          }))

          item.subsituteItems.push(...mappedItems)
        }
      })
    })
  }

  @Mutation
  private SET_GiftPackItems(packItems: Array<ProductConfigurationTemplateItem>) {
    this.giftPackItems.length = 0
    if (packItems) {
      packItems.forEach(p => {
        const pi = p
        if (!pi.item) {
          pi.item = this.items.find(q => q.id === p.id)
        }
      })
      this.giftPackItems.push(...packItems)
    }
  }

  @Action
  async CustomizePreconfiguredCustomPack(input: { id: string; size: PackSize }) {
    const packItems: Array<number> = []
    const proteins: Array<ProteinType> = []
    if (this.giftPack.id === input.id) {
      packItems.push(...this.giftPack.activeTemplate.items.map(p => Number.parseInt(p.id, 10)))
      proteins.push(
        ...uniq(this.featuredPacks.find(p => p.id === input.id)!.activeTemplate.items.map(p => p.item!.proteinType))
      )
    } else {
      packItems.push(
        ...this.featuredPacks.find(p => p.id === input.id)!.activeTemplate.items.map(p => Number.parseInt(p.id, 10))
      )
      proteins.push(
        ...uniq(this.featuredPacks.find(p => p.id === input.id)!.activeTemplate.items.map(p => p.item!.proteinType))
      )

      this.context.commit('SET_PackName', this.featuredPacks.find(p => p.id === input.id)?.name)
    }
    const subs = await GetQuickAccessBYOSubsituteItems({
      center: ShippingCenter.EastCoastCenter2,
      items: uniq(packItems),
      proteinFilters: proteins,
      restrictToEqualValue: false
    })

    this.context.commit('SET_AttachSubsituteItemsToPreconfiguredPack', { id: input.id, subsitutes: subs })

    const packItemsToCustomize: Array<ProductConfigurationTemplateItem> = []
    if (input.id === this.giftPack.id) {
      packItemsToCustomize.push(...this.giftPack.activeTemplate.items)
      if (input.size === PackSize.Large) {
        const newItems: Array<ProductConfigurationTemplateItem> = []
        packItemsToCustomize.forEach(p => {
          newItems.push({
            id: p.id,
            item: p.item,
            shippingQuantity: 1,
            itemId: p.itemId,
            itemTemplateTypeId: p.itemTemplateTypeId,
            referenceItemPrice: p.referenceItemPrice,
            quantity: p.quantity,
            createdDate: p.createdDate,
            productConfigurationTemplateId: p.productConfigurationTemplateId,
            surcharge: p.surcharge,
            updatedDate: p.updatedDate,
            subsituteItems: p.subsituteItems,
            displayOrder: p.displayOrder + 1
          })
        })
        packItemsToCustomize.push(...newItems)
      }
    } else {
      packItemsToCustomize.push(...this.featuredPacks.find(p => p.id === input.id)!.activeTemplate.items)
      if (input.size === PackSize.Large) {
        const newItems: Array<ProductConfigurationTemplateItem> = []
        packItemsToCustomize.forEach(p => {
          newItems.push({
            id: p.id,
            item: p.item,
            shippingQuantity: 1,
            itemId: p.itemId,
            itemTemplateTypeId: p.itemTemplateTypeId,
            referenceItemPrice: p.referenceItemPrice,
            quantity: p.quantity,
            createdDate: p.createdDate,
            productConfigurationTemplateId: p.productConfigurationTemplateId,
            surcharge: p.surcharge,
            updatedDate: p.updatedDate,
            subsituteItems: p.subsituteItems,
            displayOrder: p.displayOrder + 1
          })
        })
        packItemsToCustomize.push(...newItems)
      }
    }
    this.context.commit(
      'SET_GiftPackItems',
      orderBy(packItemsToCustomize, p => p.displayOrder)
    )
  }

  @Action
  async CustomizeWithSelectedProteins(input: { selectedProteins: Array<ProteinType> }) {
    const customPack = await GetQuickAccessBYOCustomPackItems(input)
    const packItems: Array<LegacyPackItem> = customPack.items
    const config = {
      id: 'NONE',
      shippingCenter: ShippingCenter.EastCoastCenter2,
      name: '',
      description: '',
      activeTemplate: {
        productConfigurationTemplateId: '',
        productConfigurationTemplateType: ProductConfigurationTemplateType.Standard,
        contactId: '',
        templateName: `Template From ${customPack.id}`,
        packSize: PackSize.Standard,
        maximumProductPrice: 97,
        surcharge: 0,
        isActiveTemplate: true,
        items: packItems.map(p => ({
          productConfigurationTemplateId: '',
          itemId: parseInt(p.id),
          item: this.items.find(q => q.id === p.id),
          itemTemplateTypeId: ItemTemplateType.StandardReoccuring,
          referenceItemPrice: p.maxSwapPrice ?? 0,
          surcharge: 0,
          quantity: 0,
          shippingQuantity: p.shippingQuantity,
          displayOrder: p.displayOrder / 100,
          createdDate: new Date(Date.now()),
          updatedDate: new Date(Date.now()),
          id: p.id,
          subsituteItems: new Array<SubstituteItem>()
        })),
        createdDate: new Date(Date.now()),
        updatedDate: new Date(Date.now())
      },
      isReadonly: false,
      surcharge: 0,
      discount: 0,
      listPrice: 0
    }

    let i = 0
    sortBy(config.activeTemplate.items, p => p.displayOrder).forEach(q => {
      q.displayOrder = i++
    })

    config.activeTemplate.items = sortBy(config.activeTemplate.items, p => p.displayOrder)
    const packItemsToCustomize: Array<ProductConfigurationTemplateItem> = []

    const subs = await GetQuickAccessBYOSubsituteItems({
      center: ShippingCenter.EastCoastCenter2,
      rangeItems: uniq(
        config.activeTemplate.items
          .filter(
            q =>
              q.itemTemplateTypeId === ItemTemplateType.StandardOnetime ||
              q.itemTemplateTypeId === ItemTemplateType.StandardReoccuring
          )
          .map(p => {
            return { displayOrder: p.displayOrder, maxSwapPrice: p.referenceItemPrice }
          })
      ),
      proteinFilters: input.selectedProteins,
      restrictToEqualValue: false
    })

    packItemsToCustomize.push(...config.activeTemplate.items)

    this.context.commit(
      'SET_GiftPackItems',
      orderBy(packItemsToCustomize, p => p.displayOrder)
    )

    this.context.commit('SET_AttachSubsituteItemsToPreconfiguredPack', { id: 'NONE', subsitutes: subs })

    this.context.commit('SET_PackName', 'Build Your Own')
  }

  @Mutation
  private SET_ErrorMessage(errorMessage: string) {
    this.errorMessage = errorMessage
  }

  @Mutation
  private SET_OrderId(value: string) {
    this.orderId = value
  }

  @Mutation
  private SET_OrderComplete() {
    this.signupStatus = SignupStatus.Completed
  }

  @Action
  // eslint-disable-next-line class-methods-use-this
  async ProcessGiftCardSignup(): Promise<SignupResult> {
    const result = await ProcessGiftCardSignup({
      customerContactInfo: this.signupInfo.customerContactInfo,
      subscriptionConfiguration: {
        frequency: this.signupInfo.subscriptionConfiguration.frequency,
        packSize: this.signupInfo.subscriptionConfiguration.packSize,
        proteinTypes: this.selectedProteins
      },
      giftCardId: this.signupInfo.giftCard.giftCardId,
      shippingAddress: this.signupInfo.shippingAddress,
      firstShipmentDate: this.signupInfo.firstShipmentDate!,
      selectedItems: this.selectedItems.map(p => p.id)
    })
    if (result.errorMessage) {
      this.context.commit('SET_ErrorMessage', result.errorMessage)
    } else {
      this.context.commit('SET_OrderId', result.orderId)
      this.context.commit('SET_OrderComplete')
    }
    return result
  }
}
