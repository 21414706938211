import {
  Contact, ShippingAddress, SignupResult, SubscriptionConfiguration
} from '@/modules/common'
import { GiftCard } from '@/modules/gift-redemption'
import axios from 'axios'

export async function confirmGiftCard(giftCardId: string): Promise<GiftCard> {
  const result = await axios.get<GiftCard>(`/api/v0/gift-card/get-gift-card-info/${giftCardId}`)
  return result.data
}

export async function ProcessGiftCardSignup(input: {
    customerContactInfo: Contact;
    shippingAddress: ShippingAddress;
    subscriptionConfiguration: SubscriptionConfiguration;
    giftCardId: string;
    firstShipmentDate: Date;
    selectedItems: Array<string>;
}): Promise<SignupResult> {
  const result = await axios.post<SignupResult>('/api/v0/gift-card/signup', input)
  return result.data
}
