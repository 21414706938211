









import GiftRedemptionModule from '@/store/modules/gift-redemption'
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators'
import CustomProteinSelector from '@/components/common/CustomProteinSelector/index.vue'
import { ProteinType } from '@/modules/common'

@Component({
  components: { CustomProteinSelector }
})
export default class GiftRedemptionStart extends Vue {
  giftRedemptionModule: GiftRedemptionModule = getModule(GiftRedemptionModule, this.$store)

  async proteinsSelected(selectedProteins: Array<ProteinType>) {
    this.$emit('toggle-overlay', { message: 'Please wait, we are curating your pack.' })
    await this.giftRedemptionModule.CustomizeWithSelectedProteins({
      selectedProteins
    })
    this.$emit('toggle-overlay', { message: '' })
    this.$router.push({
      name: 'gift-redemption.customize'
    })
  }
}
