import { Contact, ShippingAddress, SubscriptionConfiguration } from '../common'

export enum GiftCardStatus {
    Unknown = 0,
    Issued = 1,
    Reedeemed = 2,
    Expired = 3,
    Revoked = 4,
    Replaced = 5,
    NotFound = 6,
}

export interface GiftCard {
    giftCardId: string;
    giftCardStatus: GiftCardStatus;
    toFriendly: string;
    fromFriendly: string;
    note: string;
    issueDate: string;
    expirationDate: string;
    redemptionDate: string;
    recipientEmailAddress: string;
    recipientFirstName: string;
    recipientLastName: string;
}

export interface GiftCardSignup {
    giftCard: GiftCard;
    customerContactInfo: Contact;
    shippingAddress: ShippingAddress;
    subscriptionConfiguration: SubscriptionConfiguration;
    firstShipmentDate: Date | undefined;
}
