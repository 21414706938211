


























































































import GiftRedemptionModule from '@/store/modules/gift-redemption'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import Quotes from '@/components/signup/Quotes/index.vue'
import CommonModule from '@/store/modules/common'

@Component({
  components: { Quotes }
})
export default class GiftRedemptionStart extends Vue {
  giftRedemptionModule: GiftRedemptionModule = getModule(
    GiftRedemptionModule,
    this.$store
  );

  commonModule: CommonModule = getModule(
    CommonModule,
    this.$store
  );

  mask = 'NNNN-NNNN-NNNN';

  canSubmit = false;

  @Prop()
  giftCardId!: string;

  localGiftCard = '';

  async mounted() {
    this.canSubmit = false
    await this.commonModule.SetQuickActionTitle('Reedem Your Gift')
  }

  @Watch('giftCardId', { immediate: true })
  onGiftCardChanged(newValue: string) {
    if (newValue !== undefined) {
      this.$emit('gift-card-id-populated', newValue)
    }
  }

  @Watch('localGiftCard')
  onLocalGiftCardChanged() {
    this.canSubmit = true
  }

  verifyGiftCard() {
    if ((this.$refs.form as any).validate() && this.canSubmit) {
      this.canSubmit = false
      this.$router.push(`/gift-redemption/redeem/${this.localGiftCard.replaceAll('-', '')}`)
    }
  }

  pickYourGift() {
    this.$router.push('/gift-redemption/pick-your-gift')
  }

  get hasValidGiftCard() {
    return this.giftRedemptionModule.hasValidGiftCard
  }

  get firstName() {
    return this.giftRedemptionModule.signupInfo.customerContactInfo.firstName
  }

  get giftInfo() {
    return this.giftRedemptionModule.giftCardInfo
  }

  get shouldShowAlert() {
    return this.giftCardStatus !== '' && this.canSubmit === false
  }

  get giftCardStatus() {
    return this.giftRedemptionModule.giftCardStatus
  }

  giftCardRules = [(v: string) => !!v || 'Gift Card Code Is Required'];
}
